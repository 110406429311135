// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ask-a-tech-js": () => import("./../src/pages/ask-a-tech.js" /* webpackChunkName: "component---src-pages-ask-a-tech-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-modal-basic-js": () => import("./../src/pages/services-modal-basic.js" /* webpackChunkName: "component---src-pages-services-modal-basic-js" */),
  "component---src-pages-services-modal-premium-js": () => import("./../src/pages/services-modal-premium.js" /* webpackChunkName: "component---src-pages-services-modal-premium-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-thank-you-js": () => import("./../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

